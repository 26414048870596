import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { AuthService } from '@ea/auth';
import { ToastService } from '@ea/services';
import { Router } from '@angular/router';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MatIcon, FormsModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent {
  videoUrl: string = 'https://youtu.be/mwUN3cBnohQ?si=AhkBb5nwDtB9D4fq';
  safeUrl: any;
  isLaod: boolean = false;
  formData = {
    hasLivedExperience: '', // "Do you have lived experience of being disabled?"
    abandonmentFrequency: '', // "How often do you leave or abandon websites due to accessibility issues?"
    abandonmentReasons: {
      // "What are the main reasons you leave a website without completing a purchase?"
      billingAddress: false, // "Use this address as my billing address"
      screenReader: false, // "Unable to use a screen reader"
      keyboardNavigation: false, // "Difficult to navigate using only a keyboard"
      poorContrast: false, // "Poor contrast or unreadable text"
      complexCheckout: false, // "Complex checkout process"
      overwhelmingImagery: false, // "Overwhelm linked to imagery and/or videos"
      overwhelmingPopups: false, // "Overwhelm linked to ‘pop-up’ messages"
      confusingInstructions: false, // "Confusing instructions or product information"
      otherReasons: '', // "Other reasons" (text input)
      isOtherResons: false,
    },
    switchToOtherWebsiteFrequency: '', // "How often do accessibility issues make you switch to another website that is more accessible?"
    purchaseAbandonmentFrequency: '', // "How many purchases have you abandoned in the last 12 months because of website accessibility issues?"
    timeSpentOvercomingBarrier: '', // "How much time do you typically spend trying to overcome an accessibility barrier before leaving a website?"
    likelihoodToReturnIfImproved: '',
    frequencyOfLeaving: '', // "If a website with accessibility barriers improved, how likely are you to return and make a purchase?",
    story: '',
    email: '',
    useFeedbackConsent: false,
    useCommunicationConsent: false,
  };

  feedcheckbox = {
    useFeedbackConsent: true, // The first checkbox is checked by default
    useCommunicationConsent: true, // The second checkbox is checked by default
  };
  submitForm() {
    this.isLaod = true;
    this.formData.useFeedbackConsent = this.feedcheckbox.useFeedbackConsent;
    this.formData.useCommunicationConsent =
      this.feedcheckbox.useCommunicationConsent;

    this.FB.sendEvent('click', {
      Page_type: 'about us',
      Page_name: 'about us',
      Click_name: 'Button',
      Click_value: 'submit',

      conversion_label: 'EnableAll',
    });
    this.authService.insertSurevy(this.formData).subscribe((_) => {
      this.toast.show(
        `Thank you for taking part in our survey, your feedback has been received`,
        'success'
      );
      this.route.navigate(['/']);
      this.isLaod = false;
    });
  }
  constructor(
    private _sanitizer: DomSanitizer,
    private authService: AuthService,
    private readonly FB: FreshPaintService,
    private toast: ToastService,
    private route: Router
  ) {
    this.FB.sendEvent('page_view', {
      Page_type: 'about us',
      Page_name: 'about us',

      conversion_label: 'EnableAll',
    });
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
  }
}
