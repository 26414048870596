import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BaseComponent, ToastComponent } from '@ea/components';
import { IResponseAddress, Toast } from '@ea/models';
import { AddressService, LocalStorageService } from '@ea/services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { BehaviorSubject } from 'rxjs';
import { AddressComponent } from '../../components';
import { AddressEditorComponent } from '@ea/address-editor';
import { MatIcon } from '@angular/material/icon';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'ea-addresses-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgxSkeletonLoaderModule,
    AddressComponent,
    AddressEditorComponent,
    ToastComponent,
    MatIcon,
  ],
  templateUrl: './addresses-page.component.html',
  styleUrls: ['./addresses-page.component.scss'],
})
export class AddressesPageComponent extends BaseComponent implements OnInit {
  // addressId!: string;
  addressResults = new BehaviorSubject<IResponseAddress[]>([]);
  filteredAddressList: IResponseAddress[] = []; // addressId and isDefault
  userCustomId: any;
  // default address is computed from the addressResults if there is one with isDefault set to true. use rxjs filter operator
  defaultAddress!: IResponseAddress | null;
  addressId!: string;
  loader: boolean = false;
  editAddressId: any;
  displayForm: boolean = false;
  newdisplayForm: boolean = false;
  toast?: Toast;
  newAddress() {
    this.newdisplayForm = !this.newdisplayForm;
    this.displayForm = false;
  }
  constructor(
    private addressService: AddressService,
    private storage: LocalStorageService,
    private readonly FB: FreshPaintService
  ) {
    super();
  }

  addressUpdated(event: any) {
    if (event) {
      this.toast = {
        duration: 6000,
        message: 'Address updated successfully',
        type: 'success',
      };
      this.getAddress();
      this.newdisplayForm = false;
      this.displayForm = false;
    } else {
      this.toast = {
        duration: 6000,
        message: "Can't updated address please retry later",
        type: 'error',
      };

      this.FB.sendEvent('error', {
        Page_type: 'Address book',
        Page_name: 'Edit Address',
        Error_type: 'bad request',
        Error_msg: "Can't updated address please retry later",

        conversion_label: 'EnableAll',
      });
    }
  }

  addressCreated(event: any) {
    if (event) {
      this.toast = {
        duration: 6000,
        message: 'Address created successfully',
        type: 'success',
      };
      this.getAddress();
      this.newdisplayForm = false;
      this.displayForm = false;
    } else {
      this.toast = {
        duration: 6000,
        message: "Can't create address please retry later",
        type: 'error',
      };
      this.FB.sendEvent('error', {
        Page_type: 'Address book',
        Page_name: 'Add a new address',
        Error_type: 'bad request',
        Error_msg: "Can't create address please retry later",

        conversion_label: 'EnableAll',
      });
    }
  }
  editsAddress(event: any) {
    this.showEditeAddress();
    this.editAddressId = event;
  }
  showEditeAddress() {
    this.newdisplayForm = false;
    this.displayForm = !this.displayForm;
  }
  deleteAddress(event: any) {
    this.addressService.deleteAddress(event).subscribe((x) => {
      this.getAddress();
      this.toast = {
        duration: 6000,
        message: 'Address deleted successfully',
        type: 'success',
      };
    });
  }
  closeToast() {
    this.toast = undefined;
  }
  loading() {
    this.toast = {
      duration: 80000,
      message: 'Updating your address book',
      type: 'info',
    };
  }
  getAddress() {
    this.userCustomId = this.storage.getItem('customId');
    const customId = this.storage.getItem('customId');

    if (customId) {
      this.loader = true;
      this.subscriptions.push(
        this.addressService.getAddress(customId).subscribe((response) => {
          this.addressResults.next(response);
          this.loader = false;
        })
      );
    }
    this.subscriptions.push(
      this.addressResults.subscribe((addresses) => {
        this.defaultAddress =
          addresses.find((address) => address.isDefault) || null;

        this.filteredAddressList = addresses.sort((a, b) => {
          // Sort by IsShippingAddress first (assuming it's a boolean)
          if (
            a.additionalAttributes.isShippingAddress !==
            b.additionalAttributes.isShippingAddress
          ) {
            return a.additionalAttributes.isShippingAddress ? -1 : 1; // Put true values first for shipping
          }

          // If IsShippingAddress values are equal, sort by IsBillingAddress (assuming it's a boolean)
          if (
            a.additionalAttributes.isBillingAddress !==
            b.additionalAttributes.isBillingAddress
          ) {
            return a.additionalAttributes.isBillingAddress ? -1 : 1; // Put true values first for billing
          }

          // If both shipping and billing values are equal, keep the order unchanged
          return 0;
        });
      })
    );
  }
  ngOnInit() {
    // check if address is default
    this.getAddress();
  }

  closeForm() {
    this.displayForm = false;
    this.newdisplayForm = false;
  }
}
