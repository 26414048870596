<div class="mt-2 justify-content-end" *ngIf="options$ | async as options">
  <div class="header">
    Delivery @if(options.data.length == 1){option} @else{ options } for
    <span class="font-bold"> {{ vendor.venorName }}</span>
  </div>

  <div
    *ngIf="options$ | async as options"
    class="delevery-option-container justify-content-end"
  >
    <div
      *ngFor="let option of options.data"
      [ngClass]="
        option != selected
          ? 'col address-option'
          : 'col address-option div-active'
      "
    >
      <div class="contaner justify-content-between row">
        <div class="col-1 mt-2">
          <label>
            <input
              class="form-radio-input"
              type="radio"
              [(ngModel)]="selected"
              id="{{ option.shippingMethodId }}"
              [value]="option"
              (ngModelChange)="onOptionsChanged(option)"
            />
          </label>
        </div>
        <div class="col-8">
          <h2>
            <label for="{{ option.shippingMethodId }}"
              >{{ option.description }}
            </label>
          </h2>
          <ul>
            <li *ngIf="option.cutoffTime > 0">
              • Order by {{ this.getFormattedCutOffTime(option.cutoffTime) }}
            </li>
            <li *ngIf="option.minimumDays > 1 && option.maximumDays > 2">
              • {{ option.minimumDays }} to {{ option.maximumDays }} working
              days
            </li>
            <li *ngIf="option.minimumDays == 1 && option.maximumDays == 2">
              • Next working day
            </li>
          </ul>
        </div>
        <div class="col-3">
          <h2 *ngIf="option.cost > 0">£{{ transform(option.cost) }}</h2>
          <h2 *ngIf="option.cost == 0">Free</h2>
        </div>
      </div>
    </div>
  </div>
</div>
