import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastService, VendorService } from '@ea/services';
import { Router, RouterLink } from '@angular/router';
import {
  CountryISO,
  NgxIntlTelInputModule,
  PhoneNumberFormat,
  SearchCountryField,
} from '@justin-s/ngx-intl-tel-input';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'app-seller',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    NgxIntlTelInputModule,
  ],
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss', './seller.component2.scss'],
})
export class SellerComponent {
  public contactForm!: FormGroup;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode = false;
  constructor(
    private formGroup: FormBuilder,
    private toast: ToastService,
    private vendorService: VendorService,
    private route: Router,
    private readonly FB: FreshPaintService
  ) {
    this.FB.sendEvent('page_view', {
      Page_type: 'seller',
      Page_name: 'seller',

      conversion_label: 'EnableAll',
    });
    this.contactForm = this.formGroup.group({
      firstName: ['', Validators.required],
      lastname: ['', Validators.required],
      emailAddress: ['', Validators.required],
      jobTitle: [''],
      phoneNumber: ['', Validators.required],
      sector: [''],
      isagree: this.formGroup.control(true, Validators.requiredTrue),
      currentOperatingSystem: [''],
    });
  }

  submitForm() {
    this.FB.sendEvent('click', {
      Page_type: 'seller',
      Page_name: 'Become an EnableAll seller',
      Click_name: 'Button',
      Click_value: 'Get start',

      conversion_label: 'EnableAll',
    });
    if (this.contactForm.valid) {
      const contact = {
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastname,
        emailAddress: this.contactForm.value.emailAddress,
        jobTitle: this.contactForm.value.jobTitle,
        phoneNumber: this.contactForm.value.phoneNumber.number,
        sector: this.contactForm.value.sector,
        currentOperatingSystem: this.contactForm.value.currentOperatingSystem,
      };

      this.vendorService.postVendorSubmit(contact).subscribe(() => {
        this.toast.show(
          `Thank you for your submission, an EnableAll Staff will contact you shortly!`,
          'success'
        );
        this.route.navigate(['/']);
      });
    }
  }
}
