import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CartFacade } from '../../+state';
import { map, Observable, take, tap } from 'rxjs';
import { CartItem, CartResponse, UpdateItemRequest } from '../../models';
import { AttributePipe } from '@ea/products';
import { CartTotalsComponent } from '../../components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FilterImagePipe } from 'src/app/modules/products/pipes';
import { Store } from '@ngrx/store';
import { selectAuthState } from 'src/app/modules/auth/+state/auth.selector';
import {
  AddressService,
  LocalStorageService,
  ToastService,
} from '@ea/services';
import { BaseComponent, ToastComponent } from '@ea/components';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { OrderStepsComponent } from '../../components/order-steps/order-steps.component';
import { SubtotalMobileComponent } from '../../components/subtotal-mobile/subtotal-mobile.component';
import { CartStepService } from '../../../../services/shared/cart-steps';
import { CartService } from '../../services';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'ea-cart-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    CartTotalsComponent,
    AttributePipe,
    FilterImagePipe,
    NgxSkeletonLoaderModule,
    MatIcon,
    FormsModule,
    OrderStepsComponent,
    SubtotalMobileComponent,
    ToastComponent,
  ],
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent extends BaseComponent implements OnInit {
  constructor(
    private facade: CartFacade,
    private store: Store,
    private router: Router,
    private addressService: AddressService,
    private storage: LocalStorageService,
    private toasterService: ToastService,
    private cartStepService: CartStepService,
    public cartService: CartService,
    private readonly FB: FreshPaintService
  ) {
    super();
  }
  colorKey = 'colour';
  sizeKey = 'size';
  promo: string = '';
  activeTextareaIndex: number[] = [];
  isLoading$?: Observable<boolean>;
  isLoaded$?: Observable<boolean>;
  cart$?: Observable<CartResponse>;
  promoCode: string = '';
  isAuthenticated$ = new Observable<boolean>();
  ngOnInit(): void {
    this.cart$ = this.facade.cart$;
    this.cart$
      .pipe(
        tap((x) => {
          this.isLoading$ = this.facade.isLoading$;
          this.isLoaded$ = this.facade.isLoaded$;
          if (x.items!.length > 0) {
            this.FB.sendEvent('page_view', {
              Page_type: 'Checkout',
              Page_name: 'bag',
            });
            this.FB.sendEvent('view_cart', {
              items: this.getFBItemsModel(x.items!),
              value: x.totalAmount,
              currency: 'GBP',
            });
          }
          if (x.allPromosApplied) {
            this.promoCode = x.allPromosApplied[0].promoCode;
          }
          this.isAuthenticated$ = this.store
            .select(selectAuthState)
            .pipe(map((state) => state.isAuthenticated));
          let i = 0;
          x.items?.forEach((x) => {
            if (this.getGeftCart(x)) {
              this.activeTextareaIndex.push(i);
            }
            i++;
          });
        })
      )
      .subscribe();

    let cartStep = {
      btnText: ' Bag ',
      styleClass: 'ea-button-secondary',
      icon: {
        type: 'img',
        name: 'bag-white.svg',
        class: 'bag-icon',
        alt: 'bag icon',
      },
    };

    this.cartStepService.updateStep(cartStep);
  }

  getGeftCart(item: CartItem) {
    if (item.extra.extra) {
      // Sanitize the string by replacing newlines with \n
      const sanitizedString = item.extra.extra.customValues!.replace(
        /\n/g,
        '\\n'
      );
      const jsonObject = JSON.parse(sanitizedString);
      return jsonObject.message;
    } else {
      return '';
    }
  }
  removedItem: any;
  removedItems: any;
  removeItem(cartId: string, lineItemId: number) {
    this.cart$?.subscribe((x) => {
      var items = x.items
        ?.filter((i) => i.lineItemId == lineItemId)
        .map((item) => ({
          item_id: item.sku, // Assuming each item has an id
          item_name: item.extra.parentName.value, // Assuming each item has a name
          item_category:
            item.extra.breadcrumbsString.value != null
              ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
              : '', // Assuming each item has a category
          price: item.unitPrice.sale, // Assuming each item has a price
          currency: 'GBP',
          item_list_name:
            item.extra.breadcrumbsString.value != null
              ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
              : '',
          item_brand: item.attributes.find((x) => x.name == 'brand')?.value,
          vendor_name: item.attributes.find((x) => x.name == 'vendor_name')
            ?.value,
          quantity: item.quantity,
        }));

      this.removedItems = items;
    });

    if (this.removedItems) {
      this.FB.sendEvent('remove_from_cart', {
        items: this.removedItems,
        value: this.removedItems ? this.removedItems[0].value : 0,
        currency: 'GBP',
      });
    }
    this.removedItem = lineItemId;
    this.facade.removeItem(cartId, lineItemId);
  }

  updateItem(cart: any, request: UpdateItemRequest) {
    this.facade.updateItem(cart.cartId, request);
  }

  UpdateItem(
    event: Event | null,
    cart: any,
    item: CartItem,
    quantityInput = '0'
  ) {
    var quantity = '';
    if (quantityInput == '0' && event != null) {
      quantity = (event.target as HTMLInputElement).value;
    } else {
      quantity = quantityInput;
    }
    var message = '';
    if (quantityInput == '-1' && event != null) {
      message = (event.target as HTMLInputElement).value;
    }
    const request: UpdateItemRequest = {
      cartId: cart.cartId,
      name: cart.metadata.name,
      items: [
        {
          itemId: item.itemId,
          lineItemId: item.lineItemId,
          priceListId: Number(item.priceListId),
          quantity: Number(quantity),
          giftMessage: message,
          isMessageChange: quantityInput == '-1',
        },
      ],
    };
    this.updateItem(cart, request);
  }
  gotoCheckout() {
    this.toggleDisable(true);
    this.isAuthenticated$.subscribe((isAuth) => {
      if (isAuth) {
        this.cart$?.subscribe((x) => {
          this.FB.sendEvent('begin_checkout', {
            items: this.getFBItemsModel(x.items!),
            value: x.totalAmount,
            currency: 'GBP',
            coupon: x.allPromosApplied ? x.allPromosApplied[0].promoCode : '',
          });
        });

        this.router.navigate(['/checkout']);
      } else {
        const queryParams = { redirectRoute: `checkout` };
        this.router.navigate(['/cart/auth'], { queryParams });
      }
      this.toggleDisable(false);
    });
  }

  getItemTitle(item: CartItem) {
    var title = '';
    if (item.attributes.filter((x) => x.name == this.sizeKey).length > 0) {
      title += `Size: ${
        item.attributes.filter((x) => x.name == this.sizeKey)[0].value
      }<br>`;
    }
    if (item.attributes.filter((x) => x.name == this.colorKey).length > 0) {
      title += `Colour: ${
        item.attributes.filter((x) => x.name == this.colorKey)[0].value
      }\n`;
    }
    if (title == '') {
      title = item.title;
    }
    return title;
  }

  increaseQuantity(item: any, cart: any) {
    item.quantity++;
    this.UpdateItem(null, cart, item, item.quantity);
    // this.updateQuantity(item);
  }

  decreaseQuantity(item: any, cart: any) {
    if (item.quantity > 1) {
      item.quantity--;
      this.UpdateItem(null, cart, item, item.quantity);
      // this.updateQuantity(item);
    }
  }
  toggleTextarea(index: number, item: any, cart: any) {
    const idx = this.activeTextareaIndex.indexOf(index);
    if (idx > -1) {
      // If the index is already in the array, remove it (hide the textarea)
      this.activeTextareaIndex.splice(idx, 1);
    } else {
      // If the index is not in the array, add it (show the textarea)
      this.activeTextareaIndex.push(index);
    }
    if (this.getGeftCart(item)) {
      this.UpdateItem(null, cart, item, '-1');
    }
  }

  applyPromoCode(cart: any) {
    this.facade.applyPromoCode(cart.cartId, this.promo);
  }

  closeToast() {
    this.cartService.toast = null;
  }

  getFBItemsModel(items: CartItem[]) {
    if (items) {
      var fBItems = items?.map((item) => ({
        item_id: item.sku, // Assuming each item has an id
        item_name: item.extra.parentName.value, // Assuming each item has a name
        item_category:
          item.extra.breadcrumbsString.value != null
            ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
            : '', // Assuming each item has a category
        price: item.unitPrice.sale, // Assuming each item has a price
        item_list_name:
          item.extra.breadcrumbsString.value != null
            ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
            : '',
        item_brand: item.attributes.find((x) => x.name == 'brand')?.value,
        // vendor_name: item.attributes.find((x) => x.name == 'vendor_name')
        //   ?.value,
        quantity: item.quantity,
      }));
      return fBItems;
    }
    return [];
  }
}
