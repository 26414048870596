import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CartFacade } from '../../+state';
import { Observable, map, shareReplay, take, tap } from 'rxjs';
import { CartItem, CartItemsByVendor, CartResponse } from '../../models';
import { AttributePipe } from '@ea/products';
import {
  CartTotalsComponent,
  SelectDeliveryOptionsComponent,
} from '../../components';
import { CheckoutAddressesComponent } from '@ea/account';
import { FormsModule } from '@angular/forms';
import { FilterImagePipe } from 'src/app/modules/products/pipes';
import { IUser } from '@ea/models';
import { LocalStorageService, VendorService } from '@ea/services';
import { BaseComponent } from '@ea/components';
import { MatIconModule } from '@angular/material/icon';
import { OrderStepsComponent } from '../../components/order-steps/order-steps.component';
import { InfoCardComponent } from '../../components/info-card/info-card.component';
import { CartStepService } from '../../../../services/shared/cart-steps';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';

@Component({
  selector: 'ea-checkout',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    CartTotalsComponent,
    AttributePipe,
    SelectDeliveryOptionsComponent,
    CheckoutAddressesComponent,
    FilterImagePipe,
    MatIconModule,
    OrderStepsComponent,
    InfoCardComponent,
  ],
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent extends BaseComponent {
  isLoading$?: Observable<boolean>;
  isLoaded: boolean = false;
  isLoaded$?: Observable<boolean>;
  cart$: Observable<CartResponse>;
  user$?: IUser;
  selectedAddress?: any;
  currentFontSize?: string;
  useDelievrtyAddressAsBilling: boolean = false;
  additionvalues: number = 0;
  prmocode: string = '';
  isValidPromoCode: boolean = false;
  isDelevryOption: boolean = false;
  itemsByVendor?: CartItemsByVendor[];
  step: string = 'Delivery';
  estimatedShippingAmount = 0;
  isCartInvalidToCheckout$: Observable<boolean>;
  isCardLaded: boolean = false;
  showlayout = false;
  goToDeleverystep() {
    this.isDelevryOption = true;
    this.step = 'step2';
    let cartStep = {
      btnText: 'Step 2- Delivery options',
      styleClass: 'ea-button-secondary',
      icon: { type: 'img', name: 'delivery-active.svg' },
    };
    this.cartStepService.updateStep(cartStep);
  }
  estimatedShipping(vendor: CartItemsByVendor[]) {
    vendor.forEach((x) => {
      if (!x.shippingMethods) {
        x.shippingMethods = this.getShipmentMethods(x.vendorId);
      }
    });
    return (
      vendor.reduce((acc, item) => {
        return acc + (item.shipTo?.shipMethod.cost.amount ?? 0);
      }, 0) ?? 0
    );
  }

  ngOnInit(): void {
    this.FB.sendEvent('page_view', {
      Page_type: 'Checkout',
      Page_name: 'Delivery',

      conversion_label: 'EnableAll',
    });
    this.isLoading$ = this.facade.isLoading$;
    this.isLoaded$ = this.facade.isLoaded$;
  }

  onAddressChanged(address: any) {
    this.selectedAddress = address;
    this.selectedAddress.email = this.user$?.emailAddress;
  }

  useAsBillingAddress() {
    this.useDelievrtyAddressAsBilling = true;
  }

  constructor(
    private facade: CartFacade,
    private storageService: LocalStorageService,
    private cartStepService: CartStepService,
    private router: Router,
    private readonly FB: FreshPaintService,
    private vendorService: VendorService
  ) {
    super();
    this.user$ = this.storageService.getItem('user');
    this.cart$ = this.facade.cart$.pipe(
      tap((cart) => {
        if (cart.cartId) {
          this.isCardLaded = true;
          const hash: {
            [key: string]: CartItem[];
          } = {};
          let totral = 0;
          cart.items?.forEach((x) => {
            const key =
              x.attributes.find((a) => a.name === 'vendor_id')?.value ?? '';
            const existing = hash[key] ?? [];
            hash[key] = [...existing, x];
          });
          this.additionvalues = totral;
          if (!this.itemsByVendor) {
            this.itemsByVendor = Object.keys(hash).map((key) => {
              const value = hash[key];
              const firstItem = value[0];
              const vendorName =
                firstItem.attributes.find((a) => a.name === 'vendor_name')
                  ?.value ?? '';
              this.isLoaded = true;
              return {
                vendorId: key,
                shipTo: firstItem.shipTo,
                venorName: vendorName,
                items: value,
                shippingMethods: undefined,
              };
            });
            if (this.itemsByVendor && this.itemsByVendor.length > 0) {
              this.estimatedShippingAmount = this.estimatedShipping(
                this.itemsByVendor
              );
            }
          }
        }
      })
    );

    this.isCartInvalidToCheckout$ = this.cart$.pipe(
      map((x) => {
        return (
          !x.items ||
          !x.items.length ||
          x.items.some(
            (i) => !i.shipTo.shipMethod.shipMethodId || !i.shipTo.shipToId
          )
        );
      })
    );

    //update cart step
    let cartStep = {
      btnText: ' Step 1- Delivery address ',
      styleClass: 'ea-button-secondary',
      icon: { type: 'img', name: 'delivery-active.svg' },
    };
    this.cartStepService.updateStep(cartStep);
  }

  getShipmentMethods(vendorId: any) {
    return this.vendorService.getShippingMethods(vendorId).pipe(
      shareReplay(1),
      take(1),
      tap((x) => {
        x.data.map((item) => {
          item.description = item.description.split('|')[0];
        });
      })
    );
  }
  applyPromoCode() {
    this.facade.applyPromoCode(
      this.storageService.getItem('cartId'),
      this.prmocode
    );
  }

  removePromoCode(promo: string) {
    this.facade.removePromoCode(this.storageService.getItem('cartId'), promo);
  }

  show() {
    this.showlayout = !this.showlayout;
  }

  gotoPayment() {
    this.toggleDisable(true);
    this.cart$?.subscribe((x) => {
      var items = x.items?.map((item) => ({
        item_id: item.sku, // Assuming each item has an id
        item_name: item.extra.parentName.value, // Assuming each item has a name
        item_category:
          item.extra.breadcrumbsString.value != null
            ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
            : '', // Assuming each item has a category
        price: item.unitPrice.sale, // Assuming each item has a price
        currency: 'GBP',
        item_list_name:
          item.extra.breadcrumbsString.value != null
            ? item.extra.breadcrumbsString.value.split('>')[0].trimEnd()
            : '',
        item_brand: item.attributes.find((x) => x.name == 'brand')?.value,
        vendor_name: item.attributes.find((x) => x.name == 'vendor_name')
          ?.value,
        quantity: item.quantity,
      }));

      this.FB.sendEvent('add_shipping_info', {
        items: items,
        value: x.totalAmount,
        currency: 'GBP',
        coupon: x.allPromosApplied ? x.allPromosApplied[0].promoCode : '',
      });
    });

    this.router.navigate(['/payment'], {
      queryParams: { billing: this.selectedAddress.addressId },
    });
  }
}
