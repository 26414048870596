import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'ea-thank-you-page',
  templateUrl: './Thank-you-page.component.html',
  styleUrls: ['./Thank-you-page.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, FormsModule],
})
export class ThankYouPageComponent implements OnInit {
  constructor(private readonly FB: FreshPaintService) {
    this.FB.sendEvent('create_account', {});
  }

  ngOnInit(): void {}
}
