import {
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Observable, shareReplay, take, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { VendorService } from '@ea/services';
import { MatIcon } from '@angular/material/icon';
import { ShippingResponse } from '../../models/cart.model';

@Component({
  selector: 'ea-delivery-options',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIcon],
  providers: [DatePipe],
  templateUrl: './delivery-option.component.html',
  styleUrls: ['./delivery-option.component.scss'],
})
export class DeliveryOptionsComponent implements OnInit {
  @Input() vendorId?: string;
  @Input() shipmentMethodId?: string;
  @Output() optionChanged = new EventEmitter<any>();
  @Input() vendor: any;
  @Input() options$?: Observable<ShippingResponse>;
  selected: any;
  ShowCardList = false;
  maxCost: number = 0;
  ngOnInit(): void {
    
    this.mapOptions();
  }


  mapOptions(){
    if (this.vendorId && this.options$) {

    this.options$
    .pipe(
      take(1),
      tap((x) => {
        const saved = x.data.find(
          (x) => x.shippingMethodId === this.shipmentMethodId
        );
        x.data = x.data.sort((a, b) => a.cost - b.cost);

        this.selected = saved ?? this.selected; // Preserve default value if no 'saved'
        if (!this.selected) {
          this.shipmentMethodId = x.data[0].shippingMethodId;
          this.onOptionsChanged(x.data[0]);
          this.mapOptions();
        }
        this.vendor.items.forEach((x: any) => {
          this.maxCost += x.totalPrice.amount;
        });
        x.data = x.data
          .filter((x) => x.cartValue.min < this.maxCost)
          .sort((a, b) => a.cost - b.cost);

    
        x.data.map((item) => {
          item.description = item.description.split('|')[0];
        });
      })
    )
    .subscribe();
  }
  }
  transform(value: number, digits: number = 2): string {
    return value.toFixed(digits);
  }
  onOptionsChanged(option: any) {
    this.optionChanged.emit(option);

  }

  showList() {
    this.ShowCardList = !this.ShowCardList;
  }

  getFormattedCutOffTime(shipMethod: any): string {
    const date = new Date();
    date.setHours(shipMethod, 0, 0, 0); // Set hours and minutes
    return this.datePipe.transform(date, 'h:mm a')!;
  }
  constructor(
    private vendorService: VendorService,
    public datePipe: DatePipe
  ) {}
}
