import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CartFacade } from '../../+state';
import { Observable } from 'rxjs';
import { CartResponse } from '../../models';
import { PaymentFormComponent } from '../../components/payment-form';
import { AuthFacade } from '@ea/auth';
import { IUser } from '@ea/models';
import { OrderStepsComponent } from '../../components/order-steps/order-steps.component';
import { CartStepService } from '../../../../services/shared/cart-steps';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'ea-payment',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    PaymentFormComponent,
    OrderStepsComponent,
  ],
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent {
  cart$?: Observable<CartResponse>;
  user$?: Observable<IUser>;
  bullingAddress?: string | null;
  newBullingAddress?: string | null;
  ngOnInit(): void {
    this.cart$ = this.facade.cart$;
    this.user$ = this.authFacade.user$;
    this.FB.sendEvent('page_view', {
      Page_type: 'Checkout',
      Page_name: 'Payment',
    });
    this.bullingAddress = this.route.snapshot.queryParamMap.get('billing');
    this.newBullingAddress =
      this.route.snapshot.queryParamMap.get('newBullingAddress');

    let cartStep = {
      btnText: 'Step 3 - Secure payment',
      status: 'active',
      styleClass: 'ea-button-secondary',
      icon: { type: 'icon', name: 'lock', class: '' },
    };
    this.cartStepService.updateStep(cartStep);
  }

  constructor(
    private readonly FB: FreshPaintService,

    private facade: CartFacade,
    private authFacade: AuthFacade,
    private route: ActivatedRoute,
    private cartStepService: CartStepService
  ) {}
}
