import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
import { StripeService } from 'src/app/services/shared/Stripe/stripe.Service';
declare var freshpaint: any;
@Component({
  selector: 'app-cards-grid',
  templateUrl: './cards-grid.component.html',
  styleUrls: ['./cards-grid.component.scss'],
})
export class CardsGridComponent {
  @Input() styles?: any;
  @Input() cardHeader?: string;
  @Input() cardTitle?: string;
  @Input() last4?: string;
  @Input() cardImage?: string;
  @Input() paymentMethodId?: string;
  showDateForm: boolean = false;
  @Output() cardClosed = new EventEmitter();
  @Output() toaster = new EventEmitter<any>();
  form: any;
  isNotValidValid: boolean = false;

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private readonly FB: FreshPaintService
  ) {
    this.form = this.fb.group({
      expiryMonth: [null, [Validators.required]],
    });
  }

  toggleDateForm() {
    this.showDateForm = !this.showDateForm;
  }
  saveCard() {
    const raw = this.form.getRawValue();
    var request = {
      exp_month: raw.expiryMonth.split('/')[0],
      exp_year: raw.expiryMonth.split('/')[1],
      paymentMethodId: this.paymentMethodId,
    };

    this.stripeService.updatePaymentMethod(request).subscribe(
      (x) => {
        if (x) {
          this.toaster.emit({
            duration: 6000,
            message: 'Your card is Updated successfully',
            type: 'success',
          });
        } else {
          this.FB.sendEvent('error', {
            Page_type: 'Cart',
            Page_name: 'My Cart',
            Error_type: 'bad request',
            Error_msg: "Sorry, we couldn't update your card",
          });
          this.toaster.emit({
            duration: 6000,
            message: "Sorry, we couldn't update your card",
            type: 'error',
          });
        }
      },
      (error) => {
        this.FB.sendEvent('error', {
          Page_type: 'Cart',
          Page_name: 'My Cart',
          Error_type: 'bad request',
          Error_msg: "Sorry, we couldn't update your card",
        });
        this.toaster.emit({
          duration: 6000,
          message: "Sorry, we couldn't update your card",
          type: 'error',
        });
      }
    );
  }
}
